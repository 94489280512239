<template>
  <HaDropdown alignment="right">
    <template #trigger>
      <HaButton
        class="cta"
        variant="outline"
        icon-side="right"
      >
        <span class="cta__text">{{ $t('administration.shareShort') }}</span>
        <template #icon>
          <font-awesome-icon :icon="faShareNodes" fixed-width />
        </template>
      </HaButton>
    </template>
    <template #content>
      <ha-menu-list>
        <ha-menu-list-item
          v-for="item in items"
          :key="item.id"
          :class="`HaMenuListItem-${
            item.name.charAt(0).toUpperCase() +
            item.name.slice(1)
          }`"
          :icon="item.icon"
          :label="item.label"
          :data-ux="item.dataUx"
          @click="openSharePopup(item.href, false, item.label)"
        />
        <ha-menu-list-item
          class="HaMenuListItem-Email"
          :icon="faSquareEnvelope"
          :label="
            i18n.t('share.buttonLabel.email.name')
          "
          :href="`mailto:?subject=${i18n.t(`share.prefilledText.email.${form.formType.toLowerCase()}`, [
              form.title])}&body=${form.url}`"
          rel="nofollow"
        />
        <ha-menu-list-item
          class="HaMenuListItem-CopyUrl"
          :icon="faLink"
          :label="
            i18n.t('share.buttonLabel.copyUrl')
          "
          @click="copyUrl(form.url)"
        />
        <ha-menu-list-item
          v-if="isNativeShareAvailable"
          class="HaMenuListItem-genericShare"
          :icon="faGearCode"
          :label="
            i18n.t(
              'share.buttonLabel.genericShare.name'
            )
          "
          @click="openSharePopup(null, true, i18n.t('share.buttonLabel.genericShare.name'))"
        />
      </ha-menu-list>
    </template>
  </HaDropdown>

</template>

<script setup>
import { useContext } from '@nuxtjs/composition-api'
import { computed, onBeforeMount, ref, inject } from 'vue'
import {
  HaButton,
  HaDropdown,
  HaMenuList,
  HaMenuListItem
} from '@ha/components'
import {
  faSquareEnvelope,
  faLink,
  faGearCode
} from '@fortawesome/pro-solid-svg-icons'
import {
  faShareNodes
} from '@fortawesome/pro-regular-svg-icons'
import {
  faFacebookSquare,
  faSquareXTwitter,
  faLinkedin,
  faWhatsappSquare
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useStoreData from '@/composables/useStoreData'
import useTracking from '@/composables/useTracking'

const { form } = useStoreData()
const { trackingTrack } = useTracking()
const { i18n } = useContext()

const isNativeShareAvailable = ref('')

const $notifications = inject('$notifications')

onBeforeMount(() => {
  isNativeShareAvailable.value = 'canShare' in navigator
})

const items = computed(() => {
  return [
    {
      name: 'facebook',
      icon: faFacebookSquare,
      label: i18n.t(`share.buttonLabel.facebook.name`),
      href: `https://www.facebook.com/sharer/sharer.php?u=${form.value.url}`,
    },
    {
      name: '𝕏',
      icon: faSquareXTwitter,
      label: i18n.t(`share.buttonLabel.twitter.name`),
      href: `https://twitter.com/intent/tweet?text=${i18n.t(`share.prefilledText.twitter.${form.value.formType.toLowerCase()}`, [form.value.title, form.value.url])}`,
    },
    {
      name: 'linkedin',
      icon: faLinkedin,
      label: i18n.t(`share.buttonLabel.linkedin.name`),
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${form.value.url}&title=${form.value.formSlug}&source=HelloAsso`
    },
    {
      name: 'whatsapp',
      icon: faWhatsappSquare,
      label: i18n.t('share.buttonLabel.whatsapp.name'),
      href: `https://api.whatsapp.com/send?text=${i18n.t(`share.prefilledText.whatsapp.${form.value.formType.toLowerCase()}`, [form.value.title, form.value.url])}`
    }
  ]
})

const copyUrl = (href) => {
  navigator.clipboard.writeText(href)
  $notifications.push({
    type: 'success',
    title: i18n.t('toast.copyUrl.title'),
    body: i18n.t('toast.copyUrl.body'),
    timeout: 5000
  })
  trackClickSegment()
}

const openSharePopup = async (href, openGenericSharing = false, media = '') => {
  if (openGenericSharing) {
    await navigator.share({
      title: document.title,
      text: form.value.description,
      url: window.location.href
    })
    return
  }

  const popupWidth = 560
  const popupHeight = 480
  const popupXPosition =
    window.screen.availLeft +
    (window.screen.availWidth - popupWidth) / 2
  const popupYPosition =
    window.screen.availTop +
    (window.screen.availHeight - popupHeight) / 2

  window.open(
    href,
    'Share',
    `width=${popupWidth},height=${popupHeight},left=${popupXPosition},top=${popupYPosition}`
  )
  trackClickSegment(media)
}

const trackClickSegment = (socialMedia) => {
  trackingTrack('Campaign Shared', {
    form_slug: form.value.formSlug,
    form_type: form.value.formType,
    sharing_type: 'Social button',
    sharing_location: !!socialMedia ? socialMedia : 'COPY_URL',
    organization_slug: form.value.organizationSlug
  })
}
</script>

<style lang="scss" scoped>
$color-facebook: #3b5999;
$color-linkedin: #0077b5;
$color-messenger: #006aff;
$color-whatsapp: #25d366;

.cta__text {
  display: none;

  @include mediaQuery(600) {
    display: inline-block;
  }
}

.HaDropdown {
  position: relative;
}

:deep(.HaDropdown--Wrapper-Right) {
  // override for desktop is easier than for mobile
  @include mediaQuery(600) {
    transform: scale(0.975) translateY(0) translateX(0)
  }
}

:deep(.HaButton-IconRight .HaButton--Icon) {
  margin: 0;

  @include mediaQuery(600) {
    margin-left: 0.75rem;
  }
}

:deep(*) {
  .HaMenuListItem {
    &:not(.HaMenuListItem-CopyUrl, .HaMenuListItem-genericShare) svg {
      width: 2rem;
      height: 2rem;
    }

    &-Facebook {
      svg {
        color: $color-facebook !important;
      }
    }

    &-𝕏 {
      svg {
        color: var(--ha-color-black) !important;
      }
    }

    &-Linkedin {
      svg {
        color: $color-linkedin !important;
      }
    }

    &-Whatsapp {
      svg {
        color: $color-whatsapp !important;
      }
    }

    &-Email {
      svg {
        color: var(--ha-color-midnight) !important;
      }
    }
  }

  .HaMenuListItem-CopyUrl, .HaMenuListItem-genericShare {
    svg {
      width: 2rem;
      height: 1.5rem;
      color: var(--ha-color-midnight);
    }
  }
}
</style>
